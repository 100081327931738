<template>
  <KTPortlet>
    <!-- <b-overlay :show="loading"> -->
    <template v-slot:title>
      <h3 class="kt-portlet__head-title">Supplier ID / Supplier Name</h3>
    </template>
    <template v-slot:body>
      <b-tabs v-model="tabIndex" content-class="mt-3">
        <b-tab title="Information" @click="onReset(0)">
          <Information></Information>
        </b-tab>
        <b-tab title="Purchase Limits" @click="onReset(1)">
          <PurchaseLimits :fields="fields" :items="items"></PurchaseLimits>
        </b-tab>
        <b-tab title="Export Settings" @click="onReset(2)">
          <ExportSettings
            :fields="fields2"
            :items="items2"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
          ></ExportSettings>
        </b-tab>
      </b-tabs>
    </template>
    <template v-slot:foot>
      <div class="operate">
        <b-button
          class="mr-2"
          v-if="tabIndex == 2"
          variant="primary"
          @click="save()"
          :disabled="disabled"
          >Save</b-button
        >
        <router-link
          :to="{
            name: 'supplier-management-list'
          }"
          class="btn btn-secondary "
          >Back to List</router-link
        >
      </div>
    </template>
    <!-- </b-overlay> -->
  </KTPortlet>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';

import Information from '@/views/content/Detail/ManagementInformation/Index.vue';
import PurchaseLimits from '@/views/content/Detail/ManagementPurchaseLimits/Index.vue';
import ExportSettings from '@/views/content/Detail/ManagementExportSettings/Index.vue';
export default {
  name: 'detail',
  components: { KTPortlet, Information, PurchaseLimits, ExportSettings },
  data() {
    return {
      loading: false,
      tabIndex: 3,
      id: null,
      disabled: false,
      fields: [
        {
          key: 'brand',
          label: 'Brand'
        },
        {
          key: 'category',
          label: 'Category'
        },
        {
          key: 'period',
          label: 'Period'
        },
        {
          key: 'purchase_limit',
          label: 'Purchase Limit'
        },
        {
          key: 'date',
          label: 'Up-to-Date Total Purchase'
        },
        {
          key: 'next_reset_on',
          label: 'Next Reset on'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      items: [
        {
          brand: 1,
          category: 40,
          period: 'Dickerson',
          purchase_limit: 'Macdonald',
          date: '2',
          next_reset_on: '22',
          action: 'Purchase Limits'
        }
      ],
      fields2: [
        {
          key: 'select',
          label: 'select',
          allSelect: false
        },
        {
          key: 'field',
          label: 'Data Field in System'
        },
        {
          key: 'excelm',
          label: 'Column Name in Excelm'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      items2: [
        {
          id: 1,
          select: false,
          field: 'Purchase ID',
          excelm: 'TE Bestellung',
          action: {
            text: 'Export Settings',
            delete: false
          }
        },
        {
          id: 2,
          select: false,
          field: 'Purchase ID2',
          excelm: 'TE Bestellung2',
          action: {
            text: 'Export Settings',
            delete: false
          }
        }
      ],
      save_data: []
    };
  },

  methods: {
    onReset() {},
    ifAllCheckbox() {
      if (this.save_data.length == this.items2.length) {
        this.fields2[0].allSelect = true;
      } else {
        this.fields2[0].allSelect = false;
      }
    },
    checkboxChange(select, index) {
      var splice_index = this.save_data.indexOf(index);
      if (select) {
        this.save_data.push(index);
      } else {
        // console.log(splice_index);
        this.save_data.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.items2.forEach(item => {
          item.select = true;
        });
      } else {
        this.items2.forEach(item => {
          item.select = false;
        });
      }
    },
    save() {},

    init() {
      this.id = this.$route.params.purchaseId;
    }
  },

  computed: {
    // ...mapState({
    //   baseConfig: (state) => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
